var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"product.category_affiliates","resource":"","creatable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showCreateCategory = true}},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"d-flex px-2",attrs:{"col":"12"}},[_c('TButtonEdit',{on:{"click":function($event){return _vm.editItem(item)}}}),_c('TButtonDelete',{on:{"click":function($event){return _vm.removeCate(item.id)}}})],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageText',{attrs:{"value":item.name,"noTranslate":""}})],1)]}},{key:"parent",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.parent)?_c('TMessageText',{attrs:{"value":item.parent.name,"noTranslate":""}}):_vm._e()],1)]}},{key:"image_url",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CImg',{staticClass:"mb-3 p-0",staticStyle:{"height":"50px","width":"50px","object-fit":"contain"},attrs:{"thumbnail":"","src":item.image_url,"placeholderColor":""}})],1)]}},{key:"affiliate_store_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.affiliate_store ? item.affiliate_store.name : '',"noTranslate":""}})],1)]}},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name,"placeholder":"Category"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true},{key:"parent-filter",fn:function(){return [_c('SSelectAffiliateCategory',{attrs:{"value":_vm.filter.parent_id,"prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "parent_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"affiliate_store_id-filter",fn:function(){return [_c('SSelectAffiliateStore',{attrs:{"value":_vm.filter['affiliate_store_id'],"prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, 'affiliate_store_id', $event)},"change":_vm.filterChange}})]},proxy:true}])}),_c('CategoryModal',{attrs:{"show":_vm.showCreateCategory},on:{"created":function($event){return _vm.fetchCategories()},"update:show":function($event){_vm.showCreateCategory=$event}}}),_c('CategoryModal',{attrs:{"show":_vm.showEditCategory,"category":_vm.detail},on:{"updated":function($event){return _vm.fetchCategories()},"update:show":function($event){_vm.showEditCategory=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }